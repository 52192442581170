function MoveHistory(props) {


  return(
    <div className="w-full h-[55%]">
      
    </div>
  )
}

export default MoveHistory;
