//The initial state of the board.
export const initialBoard = [
  [
    {
      rowId: 0,
      columnId: 0,
      color: "light",
      piece: "black-rook",
      legalMove: false,
      selected: false,
    },
    {
      rowId: 0,
      columnId: 1,
      color: "dark",
      piece: "black-knight",
      legalMove: false,
      selected: false,
    },
    {
      rowId: 0,
      columnId: 2,
      color: "light",
      piece: "black-bishop",
      legalMove: false,
      selected: false,
    },
    {
      rowId: 0,
      columnId: 3,
      color: "dark",
      piece: "black-queen",
      legalMove: false,
      selected: false,
    },
    {
      rowId: 0,
      columnId: 4,
      color: "light",
      piece: "black-king",
      legalMove: false,
      selected: false,
    },
    {
      rowId: 0,
      columnId: 5,
      color: "dark",
      piece: "black-bishop",
      legalMove: false,
      selected: false,
    },
    {
      rowId: 0,
      columnId: 6,
      color: "light",
      piece: "black-knight",
      legalMove: false,
      selected: false,
    },
    {
      rowId: 0,
      columnId: 7,
      color: "dark",
      piece: "black-rook",
      legalMove: false,
      selected: false,
    },
  ],
  [
    {
      rowId: 1,
      columnId: 0,
      color: "dark",
      piece: "black-pawn",
      legalMove: false,
      selected: false,
    },
    {
      rowId: 1,
      columnId: 1,
      color: "light",
      piece: "black-pawn",
      legalMove: false,
      selected: false,
    },
    {
      rowId: 1,
      columnId: 2,
      color: "dark",
      piece: "black-pawn",
      legalMove: false,
      selected: false,
    },
    {
      rowId: 1,
      columnId: 3,
      color: "light",
      piece: "black-pawn",
      legalMove: false,
      selected: false,
    },
    {
      rowId: 1,
      columnId: 4,
      color: "dark",
      piece: "black-pawn",
      legalMove: false,
      selected: false,
    },
    {
      rowId: 1,
      columnId: 5,
      color: "light",
      piece: "black-pawn",
      legalMove: false,
      selected: false,
    },
    {
      rowId: 1,
      columnId: 6,
      color: "dark",
      piece: "black-pawn",
      legalMove: false,
      selected: false,
    },
    {
      rowId: 1,
      columnId: 7,
      color: "light",
      piece: "black-pawn",
      legalMove: false,
      selected: false,
    },
  ],
  [
    {
      rowId: 2,
      columnId: 0,
      color: "light",
      piece: null,
      legalMove: false,
      selected: false,
    },
    {
      rowId: 2,
      columnId: 1,
      color: "dark",
      piece: null,
      legalMove: false,
      selected: false,
    },
    {
      rowId: 2,
      columnId: 2,
      color: "light",
      piece: null,
      legalMove: false,
      selected: false,
    },
    {
      rowId: 2,
      columnId: 3,
      color: "dark",
      piece: null,
      legalMove: false,
      selected: false,
    },
    {
      rowId: 2,
      columnId: 4,
      color: "light",
      piece: null,
      legalMove: false,
      selected: false,
    },
    {
      rowId: 2,
      columnId: 5,
      color: "dark",
      piece: null,
      legalMove: false,
      selected: false,
    },
    {
      rowId: 2,
      columnId: 6,
      color: "light",
      piece: null,
      legalMove: false,
      selected: false,
    },
    {
      rowId: 2,
      columnId: 7,
      color: "dark",
      piece: null,
      legalMove: false,
      selected: false,
    },
  ],
  [
    {
      rowId: 3,
      columnId: 0,
      color: "dark",
      piece: null,
      legalMove: false,
      selected: false,
    },
    {
      rowId: 3,
      columnId: 1,
      color: "light",
      piece: null,
      legalMove: false,
      selected: false,
    },
    {
      rowId: 3,
      columnId: 2,
      color: "dark",
      piece: null,
      legalMove: false,
      selected: false,
    },
    {
      rowId: 3,
      columnId: 3,
      color: "light",
      piece: null,
      legalMove: false,
      selected: false,
    },
    {
      rowId: 3,
      columnId: 4,
      color: "dark",
      piece: null,
      legalMove: false,
      selected: false,
    },
    {
      rowId: 3,

      columnId: 5,
      color: "light",
      piece: null,
      legalMove: false,
      selected: false,
    },
    {
      rowId: 3,
      columnId: 6,
      color: "dark",
      piece: null,
      legalMove: false,
      selected: false,
    },
    {
      rowId: 3,
      columnId: 7,
      color: "light",
      piece: null,
      legalMove: false,
      selected: false,
    },
  ],
  [
    {
      rowId: 4,
      columnId: 0,
      color: "light",
      piece: null,
      legalMove: false,
      selected: false,
    },
    {
      rowId: 4,
      columnId: 1,
      color: "dark",
      piece: null,
      legalMove: false,
      selected: false,
    },
    {
      rowId: 4,
      columnId: 2,
      color: "light",
      piece: null,
      legalMove: false,
      selected: false,
    },
    {
      rowId: 4,

      columnId: 3,
      color: "dark",
      piece: null,
      legalMove: false,
      selected: false,
    },
    {
      rowId: 4,
      columnId: 4,
      color: "light",
      piece: null,
      legalMove: false,
      selected: false,
    },
    {
      rowId: 4,
      columnId: 5,
      color: "dark",
      piece: null,
      legalMove: false,
      selected: false,
    },
    {
      rowId: 4,
      columnId: 6,
      color: "light",
      piece: null,
      legalMove: false,
      selected: false,
    },
    {
      rowId: 4,
      columnId: 7,
      color: "dark",
      piece: null,
      legalMove: false,
      selected: false,
    },
  ],
  [
    {
      rowId: 5,
      columnId: 0,
      color: "dark",
      piece: null,
      legalMove: false,
      selected: false,
    },
    {
      rowId: 5,
      columnId: 1,
      color: "light",
      piece: null,
      legalMove: false,
      selected: false,
    },
    {
      rowId: 5,
      columnId: 2,
      color: "dark",
      piece: null,
      legalMove: false,
      selected: false,
    },
    {
      rowId: 5,
      columnId: 3,
      color: "light",
      piece: null,
      legalMove: false,
      selected: false,
    },
    {
      rowId: 5,
      columnId: 4,
      color: "dark",
      piece: null,
      legalMove: false,
      selected: false,
    },
    {
      rowId: 5,
      columnId: 5,
      color: "light",
      piece: null,
      legalMove: false,
      selected: false,
    },
    {
      rowId: 5,
      columnId: 6,
      color: "dark",
      piece: null,
      legalMove: false,
      selected: false,
    },
    {
      rowId: 5,
      columnId: 7,
      color: "light",
      piece: null,
      legalMove: false,
      selected: false,
    },
  ],
  [
    {
      rowId: 6,
      columnId: 0,
      color: "light",
      piece: "white-pawn",
      legalMove: false,
      selected: false,
    },
    {
      rowId: 6,
      columnId: 1,
      color: "dark",
      piece: "white-pawn",
      legalMove: false,
      selected: false,
    },
    {
      rowId: 6,
      columnId: 2,
      color: "light",
      piece: "white-pawn",
      legalMove: false,
      selected: false,
    },
    {
      rowId: 6,
      columnId: 3,
      color: "dark",
      piece: "white-pawn",
      legalMove: false,
      selected: false,
    },
    {
      rowId: 6,
      columnId: 4,
      color: "light",
      piece: "white-pawn",
      legalMove: false,
      selected: false,
    },
    {
      rowId: 6,
      columnId: 5,
      color: "dark",
      piece: "white-pawn",
      legalMove: false,
      selected: false,
    },
    {
      rowId: 6,
      columnId: 6,
      color: "light",
      piece: "white-pawn",
      legalMove: false,
      selected: false,
    },
    {
      rowId: 6,
      columnId: 7,
      color: "dark",
      piece: "white-pawn",
      legalMove: false,
      selected: false,
    },
  ],
  [
    {
      rowId: 7,
      columnId: 0,
      color: "dark",
      piece: "white-rook",
      legalMove: false,
      selected: false,
    },
    {
      rowId: 7,
      columnId: 1,
      color: "light",
      piece: "white-knight",
      legalMove: false,
      selected: false,
    },
    {
      rowId: 7,
      columnId: 2,
      color: "dark",
      piece: "white-bishop",
      legalMove: false,
      selected: false,
    },
    {
      rowId: 7,
      columnId: 3,
      color: "light",
      piece: "white-queen",
      legalMove: false,
      selected: false,
    },
    {
      rowId: 7,
      columnId: 4,
      color: "dark",
      piece: "white-king",
      legalMove: false,
      selected: false,
    },
    {
      rowId: 7,
      columnId: 5,
      color: "light",
      piece: "white-bishop",
      legalMove: false,
      selected: false,
    },
    {
      rowId: 7,
      columnId: 6,
      color: "dark",
      piece: "white-knight",
      legalMove: false,
      selected: false,
    },
    {
      rowId: 7,
      columnId: 7,
      color: "light",
      piece: "white-rook",
      legalMove: false,
      selected: false,
    },
  ],
];
